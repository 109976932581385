import Loader from '@/components/Loader'
import { css } from '@emotion/react'
import loadable from '@loadable/component'
import { FunctionComponent } from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'

// const Home = loadable(() => import('@/pages/Home'), {
//   fallback: (
//     <Loader
//       css={css`
//         height: '100vh';
//       `}
//     />
//   ),
// })
const Register = loadable(() => import('@/pages/Register'), {
  fallback: (
    <Loader
      css={css`
        height: '100vh';
      `}
    />
  ),
})

const Router: FunctionComponent = props => {
  return (
    <Switch {...props}>
      <Route exact path="/" component={Register} />
      <Route exact path="/cadastro" component={Register} />
      <Redirect to="/" />
    </Switch>
  )
}

export default Router
