import Home from '@/pages/Home/locales'
import Register from '@/pages/Register/locales'

const resources = {
  en: {
    home: Home.en,
    register: Register.en,
  },
  pt: {
    home: Home.pt,
    register: Register.pt,
  },
  es: {
    home: Home.es,
    register: Register.es,
  },
}
export default resources
