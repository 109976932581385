import { Fragment, FunctionComponent } from 'react'
import Router from '@/router'
import {
  AppShell,
  Container,
  Navbar,
  ToastContainer,
} from '@conexasaude/hero-web'
import useStyles from './styles/globalStyles'
import { Global } from '@emotion/react'
import 'animate.css'

const App: FunctionComponent = () => {
  // used to work around 100vh problems
  const { root } = useStyles()

  return (
    <Fragment>
      <ToastContainer />
      <Global styles={root} />
      <AppShell fixed header={<Navbar logo="conexa">{}</Navbar>}>
        <Container sx={{ height: '100%' }}>
          <Router />
        </Container>
      </AppShell>
    </Fragment>
  )
}

export default App
